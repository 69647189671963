import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import Router, { useRouter } from 'next/router';
import getConfig from 'next/config';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import '../assets/layout/css/layout-blue.css';

import '../styles/styles.scss';
import { appWithTranslation } from 'next-i18next';
import nextI18NextConfig from '../next-i18next.config.js';
import { ApiContext, Provider } from 'api/api';
import { useEffect, useState, Suspense, useContext } from 'react';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { isNotBlank, isNullOrUndefined } from '@utils/utils';
import axios from 'axios';
import qs from 'qs';
import { ApplicationSettings } from '@components/ApplicationContext/ApplicationContext';
import AuthenticatedUser, { AuthenticatedUserContext } from '@components/AuthenticatedUser/AuthenticatedUser';
import I18NProvider, { I18NContext } from '@components/contexts/I18NProvider';
import { addLocale } from 'primereact/api';
import { ToastContext, ToastProvider } from '@components/ApplicationContext/ToastContext';
/*import LogRocket from 'logrocket';
LogRocket.init('kwu5vu/ringil');*/

addLocale('cs', {
  firstDayOfWeek: 1,
  dayNames: ['neděle', 'pondělí', 'úterý', 'středa', 'čtvrtek', 'pátek', 'sobota'],
  dayNamesShort: ['ne', 'po', 'út', 'st', 'čt', 'pá', 'so'],
  dayNamesMin: ['N', 'P', 'U', 'S', 'Č', 'P', 'S'],
  monthNames: [
    'leden',
    'únor',
    'březen',
    'duben',
    'květen',
    'červen',
    'červenec',
    'srpen',
    'září',
    'říjen',
    'listopad',
    'prosinec',
  ],
  monthNamesShort: ['led', 'ún', 'bře', 'dub', 'kvě', 'čer', 'čvn', 'srp', 'zář', 'říj', 'lis', 'pro'],
  today: 'dnes',
  clear: 'Smazat',
});
/**
 * Where to send the user after they have signed in.
 */
const onRedirectCallback = (appState) => {
  if (appState && appState.returnTo) {
    const indexOf = appState.returnTo.indexOf('?');
    if (indexOf >= 0) {
      Router.replace(appState.returnTo);
    } else {
      Router.replace({
        pathname: appState.returnTo,
        query: appState.returnTo.query,
      });
    }
  }
};

axios.defaults.paramsSerializer = function (params) {
  return qs.stringify(params, { indices: false }); // param=value1&param=value2
};

/**
 * When it hasn't been possible to retrieve a new access token.
 * @param {Error} err
 * @param {AccessTokenRequestOptions} options
 */
const onAccessTokenError = (err, options) => {
  console.error('Failed to retrieve access token: ', err);
};

/**
 * When signing in fails for some reason, we want to show it here.
 * @param {Error} err
 */
const onLoginError = (err) => {
  Router.push({
    pathname: '/oops',
    query: {
      message: err.error_description || err.message,
    },
  });
};
/**
 * When redirecting to the login page you'll end up in this state where the login page is still loading.
 * You can render a message to show that the user is being redirected.
 */
const onRedirecting = () => {
  return (
    <div>
      <h1>Signing you in</h1>
      <p>
        In order to access this page you will need to sign in. Please wait while we redirect you to the login page...
      </p>
    </div>
  );
};

function SafeHydrate({ children }) {
  return <div suppressHydrationWarning>{typeof window === 'undefined' ? null : children}</div>;
}

const MyApp = ({ Component, pageProps }) => {
  const { publicRuntimeConfig } = getConfig();
  const router = useRouter();

  useEffect(() => {
    if (isNotBlank(publicRuntimeConfig.SENTRY_DSN) && publicRuntimeConfig.SENTRY_DSN !== 'null') {
      console.log(`Installing sentry: ${publicRuntimeConfig.SENTRY_DSN}`);
      Sentry.init({
        dsn: publicRuntimeConfig.SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
      });
    } else {
      console.log(`Sentry not installed as SENTRY_DSN=${JSON.stringify(publicRuntimeConfig.SENTRY_DSN, null, 2)}`);
    }
  }, []);

  console.log(
    `Authenticating with domain: ${publicRuntimeConfig.AUTH0_DOMAIN} and clientId: ${publicRuntimeConfig.AUTH0_CLIEND_ID} and redirect URI: ${publicRuntimeConfig.AUTH0_REDIRECT_URI}`,
  );

  const token = router.query['token'] as string;

  return (
    <SafeHydrate>
      <Auth0Provider
        domain={publicRuntimeConfig.AUTH0_DOMAIN}
        clientId={publicRuntimeConfig.AUTH0_CLIEND_ID}
        redirectUri={publicRuntimeConfig.AUTH0_REDIRECT_URI}
        onRedirectCallback={onRedirectCallback}
        scope={'openid app_metadata user_metadata'}
      >
        <ToastProvider>
          <Provider>
            <AuthenticatedUser>
              <RegisterUser>
                <ApplicationSettings>
                  <I18NProvider>
                    <Component {...pageProps} />
                  </I18NProvider>
                </ApplicationSettings>
              </RegisterUser>
            </AuthenticatedUser>
          </Provider>
        </ToastProvider>
      </Auth0Provider>
    </SafeHydrate>
  );
};

interface ComponentProps {}

const RegisterUser: React.FC<ComponentProps> = ({ children }) => {
  const { publicRuntimeConfig } = getConfig();
  const { loggedUser, isAdmin, isCustomerAdmin, isCustomer, isCarrier } = useContext(AuthenticatedUserContext);

  useEffect(() => {
    if (!isNullOrUndefined(loggedUser)) {
      const u = { id: loggedUser.id.toString(), email: loggedUser.email, username: loggedUser.email };
      if (isNotBlank(publicRuntimeConfig.SENTRY_DSN) && publicRuntimeConfig.SENTRY_DSN !== 'null') {
        Sentry.setUser(u);
      }
      if (publicRuntimeConfig.INSTALL_GA === 'true') {
        // @ts-ignore
        gtag('config', 'GA_MEASUREMENT_ID', {
          user_id: u.email,
        });
      }
    }
  }, [loggedUser]);

  return (
    <div
      id="lgsauth"
      data-user-email={loggedUser?.email}
      data-user-login={`${loggedUser?.firstName} ${loggedUser?.lastName}`}
      style={{ height: '100vh' }}
    >
      {children}
    </div>
  );
};

export default appWithTranslation(MyApp, nextI18NextConfig);
