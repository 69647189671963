module.exports = {
  i18n: {
    defaultLocale: 'cs',
    locales: ['en', 'cs', 'de', 'hu', 'pl'],
    // locales: ['en', 'cs'],
  },
  // this will download the translations from locize directly, in client (browser) and server (node.js)
  // DO NOT USE THIS if having a serverless environment => this will generate too much download requests
  //   => https://github.com/locize/i18next-locize-backend#important-advice-for-serverless-environments---aws-lambda-google-cloud-functions-azure-functions-etc
  backend: {
    projectId: '989c7799-4eae-4b1b-90db-cdae2f7441de',
    apiKey: '6fad0897-e6b0-4445-8288-80dabb5b2405',
    referenceLng: 'cs',
    private: false,
    allowedAddOrUpdateHosts: ['localhost', 'logsteo-alpha.os.shipvio.com', 'alpha.ringil.com'],
    onSaved: (lng, ns) => {
      console.log(`Saved ${lng}, ${ns}`);
    },
    addPath: 'https://api.locize.app/missing/{{projectId}}/{{version}}/{{lng}}/{{ns}}',
    reloadInterval: 60000000,
  },
  use: [require('i18next-locize-backend/cjs')],
  ns: ['common'], // the namespaces nees to be listed here, to make sure they got preloaded
  serializeConfig: false, // because of the custom use i18next plugin
  saveMissing: true,
  debug: false,
};

// for a serverless environment bundle the translations first. See downloadLocales script in package.json
// and configre this file like this:
// module.exports = {
//   i18n: {
//     defaultLocale: 'en',
//     locales: ['en', 'de'],
//   }
// }
